.header {
  --icon-width: auto;
  --icon-height: auto;

  display: flex;
  place-content: center;
  background: var(--bg-color-dark);
  color: var(--color-text-on-dark);
  padding-bottom: var(--top-bar-height);
}

.content {
  & > .forwardButtonLayout {
    margin: calc(-1 * var(--size-32)) 0 var(--section-margin-md);
    width: 100%;
  }

  & > .introduction,
  & > .contactSection {
    margin-bottom: var(--section-margin-lg);
  }
}

.introduction {
  & > .overviewButtonLayout {
    margin-block: var(--size-32) var(--section-margin-lg);
    width: 100%;
  }

  & > .aboardLogo {
    margin-top: var(--size-32);
  }
}

.aboardLogo {
  --icon-width: auto;
  --icon-height: auto;
}

.explanationContent {
  & > .explanationIntro {
    margin-bottom: var(--section-margin-md);
  }

  & > .overviewButtonLayout {
    margin-block: var(--section-margin-md) var(--section-margin-lg);
    width: 100%;
  }
}

.topics {
  display: flex;
  flex-direction: column;
  gap: var(--section-margin-md);
}

.componentTopic {
  & > .topicTitleLayout {
    margin-bottom: var(--size-20);
  }
}

.topicIcon {
  font-size: var(--size-96);
}

.textLink {
  text-decoration: underline;

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }
}
