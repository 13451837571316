.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.content {
  display: flex;
  justify-content: space-between;
}
