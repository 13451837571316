/* there is no need to define classes for body-fonts, since they only define sizes */
.withBody26 {
  font: var(--font-body-26);
}

.withBody24 {
  font: var(--font-body-24);
}

.withBody22 {
  font: var(--font-body-22);
}

.withBody18 {
  font: var(--font-body-18);
}

.withBody16 {
  font: var(--font-body-16);
}
