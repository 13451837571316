.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);

  & > .buttonLayout {
    margin-top: var(--size-48);
  }
}

.checkboxButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--size-8);
  border-radius: var(--border-radius-8);
  border: var(--border-width-2) dashed var(--color-blue-400);
  padding: var(--size-16);
  background-color: var(--color-blue-300--30);
  font: var(--font-body-22);
  text-align: center;
}

.iconLayout {
  display: flex;
}

.modalInner {
  /* stylelint-disable-next-line kaliber/layout-related-properties  */ /* modalInner is child of external lib component <Modal> */
  max-height: 90dvh;
  overflow-y: auto;
  background-color: var(--bg-color-white);
  padding: var(--size-32);
  border-radius: var(--border-radius-20);

  & > .closeButtonLayout {
    margin: 0 auto var(--size-16);
  }

  & > .modalCheckboxListLayout {
    margin-top: var(--size-32);
    margin-bottom: var(--section-margin-md);
  }
}

.selectBoxes {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.componentCheckboxList {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.componentCheckboxWithLabel {
  display: flex;
}

.checkboxLabel {
  display: flex;

  & > .checkbox {
    margin-right: var(--size-12);
  }
}

.checkbox {
  border: 2px solid var(--color-blue-700);
  border-radius: var(--border-radius-6);
  background-color: var(--bg-color-white);
  color: transparent;
  transition: var(--duration-100) linear;
  transition-property: color, background-color, border-color;
}

.checkboxIsChecked {
  border-color: transparent;
  background-color: var(--bg-color-dark);
  color: var(--color-white);
}

.componentFieldGroup {
  & > .fieldGroupHeadingLayout {
    margin-bottom: var(--size-12);
  }
}
