.overlay_root {
  display: grid;
  place-content: center;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color var(--duration-300) ease-out;

  &.enabled {
    background-color: rgba(0, 0, 0, 0.35);
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-18);
  overflow: hidden;

  & > .modalCloseButtonLayout {
    margin-left: auto;
  }

  & > .modalContent {
    max-width: 90vw;
    width: max(var(--breakpointMd), 50vw);
  }
}
