.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  background-color: white;
}

.label {
  display: flex;
  gap: var(--size-8);
  align-items: flex-start;

  & > .checkbox {
    margin-top: var(--size-8);
  }
}
