.component {
  text-align: center;

  & > .shape {
    margin: 0 auto var(--size-16);
    width: var(--size-48);
  }
}

@keyframes spin {
  0% {
    rotate: 0;
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    rotate: 1turn;
    opacity: 0.5;
  }
}

.shape {
  &::after {
    content: "";
    display: block;
    width: 100%;
    aspect-ratio: 1;
    border: 6px solid var(--color-blue-700);
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
  }
}
