.component {
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;

  & > * {
    width: 100%;
  }

  & > .xs {
    max-width: var(--container-xs);
  }

  & > .sm {
    max-width: var(--container-sm);
  }

  & > .md {
    max-width: var(--container-md);
  }

  & > .lg {
    max-width: var(--container-lg);
  }
}
