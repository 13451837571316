.componentBase {
  word-break: break-word;
}

.component62 {
  font: var(--font-heading-62);
}

.component58 {
  font: var(--font-heading-58);
  letter-spacing: 0.05em;
}

.component48 {
  font: var(--font-heading-48);
  letter-spacing: 0.05em;
}

.component42 {
  font: var(--font-heading-42);
  letter-spacing: 0.05em;
}

.component36 {
  font: var(--font-heading-36);
}

.component32 {
  font: var(--font-heading-32);
}
