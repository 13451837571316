.component {
  & > .layout {
    width: 100%;
  }
}

.layout {
  display: flex;
  gap: var(--size-16);

  & > .track {
    flex: 1 1 auto;
    height: var(--size-20);
  }
}

.track {
  background-color: var(--color-blue-100);
  border-radius: var(--border-radius-pill-shape);

  & > .bar {
    height: 100%;
  }
}

.bar {
  border-radius: inherit;
  background-color: var(--color-blue-900);
}

.text {
  font: var(--font-body-18);
}
