.componentBase {
  --text-color: var(--color-white);
  --bg-color: var(--color-orange-500);
  --shadow-color: var(--color-orange-700);
  --shadow-size: var(--size-4);
  --shadow-offset: var(--shadow-size);
  --button-amplitude: 0.125rem; /* 2px */
  --button-y: 0;
  --active-color: var(--color-orange-600);
  --active-shadow-color: var(--color-orange-900);
  --icon-hover-translate: 0;
  --icon-hover-translate-direction: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--size-4);
  font: var(--font-body-22);
  border-radius: var(--border-radius-pill-shape);
  padding: var(--size-16) var(--size-24);
  background-color: var(--bg-color);
  color: var(--text-color);
  box-shadow: 0 var(--shadow-offset) 0 0 var(--shadow-color);
  translate: 0 var(--button-y);
  transition: var(--duration-100) linear;
  transition-property: box-shadow, translate, color, background-color;

  &:hover,
  &:focus-visible {
    --shadow-offset: calc(var(--shadow-size) - var(--button-amplitude));
    --button-y: var(--button-amplitude);
    --icon-hover-translate: 1;
  }

  &:active {
    --bg-color: var(--active-color);
    --shadow-color: var(--active-shadow-color);
  }

  &[disabled] {
    --button-y: 0;
    --shadow-offset: var(--shadow-size);

    opacity: 0.5;
    cursor: not-allowed;
  }

  &.hasIconLeft {
    flex-direction: row-reverse;
  }
}

.hasIconArrowRight {
  --icon-hover-translate-direction: 1;
}

.hasIconArrowLeft {
  --icon-hover-translate-direction: -1;
}

.green {
  --bg-color: var(--color-green-500);
  --shadow-color: var(--color-green-700);
  --active-color: var(--color-green-700);
  --active-shadow-color: var(--color-green-900);
}

.red {
  --bg-color: var(--color-red-500);
  --shadow-color: var(--color-red-700);
  --active-color: var(--color-red-700);
  --active-shadow-color: var(--color-red-900);
}

.blue {
  --bg-color: var(--color-blue-300);
  --shadow-color: var(--color-blue-500);
  --active-color: var(--color-blue-600);
  --active-shadow-color: var(--color-blue-700);
  --text-color: var(--color-text-default);
}

.gray {
  --bg-color: var(--color-gray-200);
  --shadow-color: var(--color-gray-400);
  --active-color: var(--color-gray-400);
  --active-shadow-color: var(--color-gray-600);
  --text-color: #444;
}

.transparent {
  --bg-color: var(--color-transparent);
  --shadow-color: var(--color-transparent);
  --text-color: var(--color-text-default);
  --active-color: var(--color-blue-600);
  --active-shadow-color: var(--color-blue-700);

  &:hover {
    --bg-color: var(--color-blue-300);
    --shadow-color: var(--color-blue-500);
  }

  &:active {
    --bg-color: var(--active-color);
    --shadow-color: var(--active-shadow-color);
  }
}

.iconLayout {
  transition: translate var(--duration-100) linear;
  translate: calc(
    var(--icon-hover-translate) * var(--icon-hover-translate-direction) *
      var(--size-4)
  );
}
