.component {
  text-align: center;
  transform-origin: top center;

  @supports (animation-timeline: view()) {
    --scale: 0.75;
    animation: grow forwards, shrink forwards;
    animation-timeline: view();
    animation-range: entry 0% entry 80%, exit 60% exit 100%;
  }
}

@keyframes grow {
  0% {
    scale: var(--scale);
  }

  100% {
    scale: 1;
  }
}

@keyframes shrink {
  0% {
    translate: 0 0;
    scale: 1;
  }

  100% {
    scale: var(--scale);
    /* component has transform-origin top center (we want that for cards down the page); animate the translate-y value so it seems like cards scrolling out of view are anchored to bottom */
    translate: 0 calc(100% * (1 - var(--scale)));
  }
}

.layout {
  display: flex;
  flex-direction: column;

  & > .pillLayout {
    margin: 0 auto var(--size-32);
  }

  & > .statement {
    margin: var(--size-40) auto;
  }
}

.statement {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .headingLayout {
    margin-bottom: var(--size-16);
  }
}

.componentStatementIllustration {
  display: flex;
  justify-content: center;
  font-size: var(--size-160);
}
