:root {
  --font-family-base: "kreon", sans-serif;
  --font-weight-base: 400;
  --font-weight-bold: bold;

  --font-family-headings: "londrina solid", sans-serif;
  --font-weight-headings: normal;

  /* use rems for better accessibility,
  but name them after pixel sizes for easier understanding */
  --font-size-62: 3.875rem;
  --font-size-58: 3.625rem;
  --font-size-48: 3rem;
  --font-size-42: 2.625rem;
  --font-size-36: 2.25rem;
  --font-size-32: 2rem;
  --font-size-26: 1.625rem;
  --font-size-24: 1.5rem;
  --font-size-22: 1.375rem;
  --font-size-18: 1.125rem;
  --font-size-16: 1rem;

  --line-height-0-9: 0.9;
  --line-height-1-15: 1.15;

  /* define font-size / line-height combinations */
  --font-62: var(--font-size-62) / var(--line-height-0-9);
  --font-58: var(--font-size-58) / var(--line-height-0-9);
  --font-48: var(--font-size-48) / var(--line-height-0-9);
  --font-42: var(--font-size-42) / var(--line-height-0-9);
  --font-36: var(--font-size-36) / var(--line-height-1-15);
  --font-32: var(--font-size-32) / var(--line-height-1-15);

  --font-26: var(--font-size-26) / var(--line-height-1-15);
  --font-24: var(--font-size-24) / var(--line-height-1-15);
  --font-22: var(--font-size-22) / var(--line-height-1-15);
  --font-18: var(--font-size-18) / var(--line-height-1-15);
  --font-16: var(--font-size-16) / var(--line-height-1-15);

  /*
  map font shorthands to usage, so we can use them like this:
    .someSelector {
      font: var(--font-body-24);
    }
  (shorthand syntax reminder: font-weight font-size / line-height font-family)
  */

  /* heading fonts */
  --font-heading-62: var(--font-weight-headings) var(--font-62)
    var(--font-family-headings);
  --font-heading-58: var(--font-weight-headings) var(--font-58)
    var(--font-family-headings);
  --font-heading-48: var(--font-weight-headings) var(--font-48)
    var(--font-family-headings);
  --font-heading-42: var(--font-weight-headings) var(--font-42)
    var(--font-family-headings);
  --font-heading-36: var(--font-weight-base) var(--font-36)
    var(--font-family-base);
  --font-heading-32: var(--font-weight-base) var(--font-32)
    var(--font-family-base);
  --font-heading-24: var(--font-weight-base) var(--font-24)
    var(--font-family-headings);

  /* body fonts */
  --font-body-26: var(--font-weight-bold) var(--font-26) var(--font-family-base);
  --font-body-24: var(--font-weight-base) var(--font-24) var(--font-family-base);
  --font-body-22: var(--font-weight-bold) var(--font-22) var(--font-family-base);
  --font-body-18: var(--font-weight-base) var(--font-18) var(--font-family-base);
  --font-body-16: var(--font-weight-base) var(--font-16) var(--font-family-base);
}
