.component {
  display: flex;
  justify-content: center;
}

.componentStar {
  position: relative;
  font-size: var(--font-size-36);
  /* use padding in componentStar instead of gap on component to prevent hover-effect being interrupted */
  padding-inline: var(--size-8);
  color: var(--color-blue-300);
  transition: var(--duration-100) linear;
  transition-property: color, scale;

  &:hover,
  &:focus-visible {
    scale: 1.2;
    color: var(--color-blue-900);
  }

  &:has(~ .componentStar:hover, ~ .componentStar:focus-visible) {
    color: var(--color-blue-900);
  }
}

.active {
  color: var(--color-blue-900);

  &:hover,
  &:focus-visible {
    scale: 1.2;
  }
}
