.layout {
  & .outro {
    margin: var(--section-margin-md) var(--size-32) 0;
  }
}

.outro {
  & > .buttonLayout {
    margin-top: var(--size-16);
  }
}
