.component {
  --padding: var(--padding-inline-default);
  background-color: var(--bg-color-light);

  &::after {
    display: block;
    content: "";
    height: var(--size-72);
    background-color: var(--bg-color-page);
  }
}

.noChildren {
  &::after {
    display: none;
  }
}

.layout {
  position: relative;
  z-index: 0;
  padding: var(--size-24) 0;

  & > .backLinkLayout {
    margin: 0 auto var(--size-40);
    width: fit-content;
  }

  & > .speechBorderLayout {
    position: absolute;
    top: calc(100% - var(--size-12));
    left: 0;
    right: 0;
  }
}

.componentBackLink {
  --icon-hover-translate: 0;

  display: flex;
  justify-content: center;
  gap: var(--size-8);
  font: var(--font-body-22);

  &:hover,
  &:focus-visible {
    --icon-hover-translate: -1;
  }

  & > .backLinkIconLayout {
    transition: translate var(--duration-100) linear;
    translate: calc(var(--icon-hover-translate) * var(--size-4));
  }
}
