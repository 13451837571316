:root {
  --border-radius-6: var(--size-6);
  --border-radius-8: var(--size-8);
  --border-radius-12: var(--size-12);
  --border-radius-20: var(--size-20);
  --border-radius-pill-shape: 100rem;

  --border-width-2: 0.125rem;

  --border-width-form-element: var(--border-width-2);
  --border-radius-form-element: var(--border-radius-6);
}
