.component {
  --step-margin: var(--section-margin-lg);

  & > .step {
    margin-top: var(--step-margin);
  }
}

.step {
  display: flex;
  justify-content: stretch;
  gap: var(--size-16);

  & > .stepContent {
    flex: 1 1 auto;
  }
}

.stepProgress {
  position: relative;
  z-index: 0;
  font-size: var(--size-40);

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    height: calc(100% + var(--step-margin));
    border-right: 6px dotted var(--color-blue-100);
    translate: -3px 0;
  }
}

.step:last-child {
  & > .stepProgress {
    &::after {
      display: none;
    }
  }
}

.stepContent {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);
  font: var(--font-body-26);
}
