.component {
  display: block;
  padding: var(--size-20) var(--size-24);
  background-color: var(--color-blue-100);
  border-radius: var(--border-radius-12);
  font: var(--font-body-26);
  text-align: left;
}

.hasHover {
  transition: scale var(--duration-100);

  &:hover {
    scale: 1.03;
  }
}

.componentMiniCardList {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}
