.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  & > .buttonLayout {
    margin-top: var(--section-margin-md);
  }
}

.inputRow {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
}

.textInput {
  font: inherit;
  border: var(--border-width-form-element) solid var(--border-color-default);
  border-radius: var(--border-radius-form-element);
  padding: var(--size-8) var(--size-16);
}

.errorExplanation,
.validationError {
  color: var(--color-red-500);
}

.componentSuccessMessage {
  & > .backButtonLayout {
    margin-top: var(--section-margin-md);
  }
}

.successMessage {
  display: flex;
  gap: var(--size-16);

  & > .successIconLayout {
    flex: 0 0 auto;
  }
}
