:root {
  --container-xs: 20rem; /* 360px */
  --container-sm: 30rem; /* 480px */
  --container-md: 48rem; /* 768px */
  --container-lg: 64rem; /* 1024px */

  --size-4: 0.25rem;
  --size-6: 0.375rem;
  --size-8: 0.5rem;
  --size-12: 0.75rem;
  --size-16: 1rem;
  --size-20: 1.25rem;
  --size-24: 1.5rem;
  --size-32: 2rem;
  --size-40: 2.5rem;
  --size-48: 3rem;
  --size-56: 3.5rem;
  --size-64: 4rem;
  --size-72: 4.5rem;
  --size-80: 5rem;
  --size-96: 6rem;
  --size-120: 7.5rem;
  --size-160: 10rem;

  --section-margin-md: var(--size-48);
  --section-margin-lg: var(--size-80);

  --padding-inline-default: var(--size-32);
  --top-bar-height: var(--size-72);

  --icon-aspect-ratio: 1;
}
