@font-face {
  font-family: 'Kreon';
  font-style: normal;
  font-weight: normal;
  src: local('Kreon Regular'), url('/fonts/Kreon-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Kreon';
  font-style: normal;
  font-weight: 300;
  src: local('Kreon Light'), url('/fonts/Kreon-Light.woff') format('woff');
}

@font-face {
  font-family: 'Kreon';
  font-style: normal;
  font-weight: bold;
  src: local('Kreon Bold'), url('/fonts/Kreon-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Londrina Solid';
  font-style: normal;
  font-weight: normal;
  src: local('Londrina Solid Regular'), url('/fonts/LondrinaSolid-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Londrina Solid';
  font-style: normal;
  font-weight: 100;
  src: local('Londrina Solid Thin'), url('/fonts/LondrinaSolid-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Londrina Solid';
  font-style: normal;
  font-weight: 300;
  src: local('Londrina Solid Light'), url('/fonts/LondrinaSolid-Light.woff') format('woff');
}

@font-face {
  font-family: 'Londrina Solid';
  font-style: normal;
  font-weight: 900;
  src: local('Londrina Solid Black'), url('/fonts/LondrinaSolid-Black.woff') format('woff');
}
