:root {
  --color-white: #fff;

  --color-blue-100: #e3f5fb;
  --color-blue-300: #d1eff8;
  --color-blue-400: #b7d9e3;
  --color-blue-500: #a4d1de;
  --color-blue-600: #b4d9e4;
  --color-blue-700: #90bdca;
  --color-blue-800: #6cc3e8;
  --color-blue-900: #182963;

  --color-blue-300--30: rgba(209, 239, 248, 0.3);
  --color-blue-800--30: rgba(108, 195, 232, 0.3);

  --color-orange-400: #f06b0a;
  --color-orange-500: #e76304;
  --color-orange-600: #cf5701;
  --color-orange-700: #c05100;
  --color-orange-900: #964103;

  --color-green-500: #4c9809;
  --color-green-700: #3d750b;
  --color-green-900: #2d5807;

  --color-red-500: #e21a00;
  --color-red-700: #ad1500;
  --color-red-900: #821102;

  --color-gray-200: #d4d4d4;
  --color-gray-400: #c2c2c2;
  --color-gray-600: #ababab;

  --color-black--10: rgba(0, 0, 0, 0.1);
  --color-transparent: transparent;

  /* map colors to usage */
  --color-text-default: var(--color-blue-900);
  --color-text-on-dark: var(--color-white);

  --bg-color-white: var(--color-white);
  --bg-color-light: var(--color-blue-300);
  --bg-color-dark: var(--color-blue-900);

  --bg-color-page: var(--color-white);

  --border-color-default: var(--color-blue-700);
}
