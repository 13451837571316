.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  & > .counterLayout {
    margin-block: var(--size-32) var(--size-8);
  }
}

.counterLayout {
  text-align: center;
}

.content {
  display: flex;
  justify-content: space-between;
}
