.component {
  padding: 0 var(--size-32);
  background-color: var(--bg-color-dark);
  color: var(--color-text-on-dark);

  & > * {
    height: 100%;
  }
}

.navBar {
  --hover-color: var(--color-blue-300);

  /* stylelint-disable-next-line kaliber/layout-related-properties */
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homeLink {
  --icon-transform: rotate(0) scale(1);
  display: flex;
  gap: var(--size-8);
  align-items: center;
  font: var(--font-heading-24);
  text-transform: uppercase;
  letter-spacing: 0.075em;

  &:hover,
  &:focus-visible {
    --icon-transform: rotate(-3deg) scale(1.1);
    color: var(--hover-color);
  }
}

.homeIcon {
  font-size: 1.5em;
  transform: var(--icon-transform);
  transition: transform var(--duration-100);
}

.aboutUsLink {
  display: flex;
  place-content: center;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--bg-color-white);
  color: var(--bg-color-dark);
  font-size: var(--font-size-32);
  transition: transform var(--duration-100);

  &:hover,
  &:focus-visible {
    background-color: var(--hover-color);
    transform: rotate(10deg) scale(1.15);
  }
}
