.component {
  display: flex;
  align-items: center;

  & > .checkbox {
    width: 1em; /* same as icon size */
  }
}

.checkbox {
  box-sizing: content-box; /* prevent border-width influencing width that's equal to icon size */
  border: var(--border-width-form-element) solid var(--border-color-default);
  border-radius: var(--border-radius-form-element);
  background-color: var(--bg-color-white);
  color: transparent;
  transition: var(--duration-100) linear;
  transition-property: color, background-color, border-color;
}

@keyframes scale {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.25;
  }
  100% {
    scale: 1;
    background-color: var(--bg-color-dark);
  }
}

.checkboxIsChecked {
  border-color: transparent;
  background-color: var(--bg-color-dark);
  color: var(--color-white);
  animation: scale var(--duration-300) forwards ease-out;
}

.componentWithLabel {
  display: flex;
}

.checkboxLabel {
  display: flex;

  & > .checkboxLayout {
    margin-right: var(--size-12);
  }
}
