.layout {
  & > .StatementsListLayout {
    margin-bottom: var(--section-margin-lg);
  }

  & > .outro {
    margin-top: var(--section-margin-lg);
  }
}

.outro {
  & > .outroHeadingLayout {
    text-align: center;
    margin-bottom: var(--size-16);
  }
}
